/* General Styling */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

.devops-circle {
  position: absolute;
  border-radius: 50%;
  filter: blur(20px);
  z-index: -1;
}

.devops-circle-top-right {
  top: 0;
  right: 0;
  width: 190px;
  height: 190px;
  background: rgba(117, 15, 161, 0.6);
  /* Purple color with transparency */
  animation: moveCircleTopRight 6s infinite alternate;
}


@keyframes moveCircleTopRight {
  0% {
    transform: translate(20px, 20px);
  }

  100% {
    transform: translate(-20px, -20px);
  }
}

/* ---roadmap styles----- */

.roadmap-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 0 20px;
}

.main-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 40px;
}

/* Card Styles */
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.roadmap-card {
  background-color: rgba(172, 47, 255, 0.3);
  color: white;
  border-radius: 15px;
  width: calc(25% - 20px);
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  min-height: 350px;

}

.roadmap-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.card-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}

.card-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-points li {
  font-size: 0.8rem;
  line-height: 1.6;
  padding-left: 10px;
  margin-bottom: 8px;
  position: relative;
}

.card-points li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #e2d3f7;
}

.module-btn {
  background-color: #5916d6;
  color: white;
  border: none;
  margin: 5px;
  padding: 5px 24px;
  width: 90%;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 10px;
  left: 48%;
  transform: translateX(-50%);
}

.module-btn:hover {
  background-color: #7225ff;
  transform: translate(-50%, -2px);
}

.module-btn:active {
  background-color: #3e8e41;
  transform: translate(-50%, 1px);

}


/* Responsive Design */
@media (max-width: 1024px) {
  .roadmap-card {
    width: calc(50% - 20px);

  }
}

@media (max-width: 768px) {
  .roadmap-card {
    width: 100%;
    /* One card per row for small screens */
  }
}