.trainer-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .trainer-heading {
    font-size: 1.8rem;
    color: #6F42C1;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 3px solid #6F42C1;
    padding-bottom: 10px;
  }
  
  .trainer-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .trainer-list li {
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .trainer-list li::before {
    content: "➤";
    color: #29013B;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (max-width: 768px) {
    .trainer-container {
      padding: 15px;
    }
  
    .trainer-heading {
      font-size: 1.5rem;
    }
  
    .trainer-list li {
      font-size: 0.9rem;
    }
  }
  