.updated-marquee-container {
  padding: 10px;
  text-align: center;
  margin-top: 4rem;
}

.updated-marquee-heading {
  font-size: 20px;
  margin-bottom: 15px;
  color: white;
  font-weight: bold;
}

.updated-marquee {
  margin: 15px 0;
}

.updated-fast-marquee {
  margin-top: 10px;
}

.updated-marquee-card {
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.updated-linkedin-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
}

.updated-linkedin-icon img {
  width: 100%;
  height: 100%;
}

.updated-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 15px 0 10px;
  object-fit: cover;
}

.updated-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.updated-company-logo {
  width: 50px;
  margin-top: 8px;
  object-fit: contain;
}

/* General styles for the button container */
.updated-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between buttons */
  margin: 20px 0;
}

/* General button styles */
.updated-custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space for icon and text */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, #64037C, #9F5CC0);
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  animation: bounce 1.5s infinite; /* Automatically animates */
}

/* Add animation effect */
.updated-custom-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: left 0.3s ease;
  z-index: 0;
}

/* Style the icon inside the button */
.updated-custom-button i {
  font-size: 20px;
  color: white;
}

/* Ensure link styles don't interfere */
.updated-custom-button a {
  color: white;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

/* Keyframe animation for subtle bounce */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
/* General styles for the button container */
.updated-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between buttons */
  margin: 20px 0;
}

/* General button styles */
.updated-custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space for icon and text */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #64037C, #9F5CC0, #64037C);
  background-size: 200% 100%;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  animation: flow 3s linear infinite, bounce 1.5s infinite; /* Added flow animation */
}

/* Add animation effect */
.updated-custom-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: left 0.3s ease;
  z-index: 0;
}

/* Style the icon inside the button */
.updated-custom-button i {
  font-size: 20px;
  color: white;
}

/* Ensure link styles don't interfere */
.updated-custom-button a {
  color: white;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

/* Keyframe animation for subtle bounce */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Keyframe animation for gradient flow */
@keyframes flow {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


@media (max-width: 768px) {
  .updated-marquee-card {
    width: 140px;
    height: auto;
    padding: 10px;
    margin: 8px;
  }

  .updated-marquee-heading {
    font-size: 18px;
  }

  .updated-button-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .updated-custom-button {
    font-size: 24px;
    padding: 6px 10px;
    margin: 5px 0;
  }

  .updated-profile-image {
    width: 60px;
    height: 60px;
    margin: 10px 0;
  }

  .updated-linkedin-icon {
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
  }

  .updated-company-logo {
    width: 40px;
    margin-top: 5px;
  }
}
