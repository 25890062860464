@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  scroll-behavior: smooth;
  scrollbar-color: red;
}

a {
  text-decoration: none;
  color: white;
}

html,
body {
  overflow-x: hidden;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: linear-gradient(to right, #29013b, #29013b, #29012b);
}

.scrollbar-thumb {
  background-color: white !important;
  border-radius: 50%;
}

.scrollbar-track_y {
  width: 12px !important;
  background-color: rgba(84, 84, 84, 0.398) !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blur-effect {
  background-color: rgba(128, 0, 128, 0.2); /* Purple with 20% opacity */
  backdrop-filter: blur(20px); /* Adjust the blur radius */
  -webkit-backdrop-filter: blur(20px); /* For Safari support */
}
