.job-preparation-container {
  font-family: "Arial", sans-serif;
  margin: 20px auto;
  max-width: 80%;
  text-align: center;
  margin-top: 4rem;
}

.job-main-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

.job-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.job-tab-button {
  padding: 10px 15px;
  margin: 5px 2px;/* Adjust margin for better spacing on small screens */
  font-size: 16px; 
  color: #333;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%; /* Make buttons stack on small screens */
  max-width: 300px; /* Limit the width to keep buttons manageable */
}

.job-tab-button.job-tab-active {
  background-color: #9608a8;
  color: white;
  font-weight: bold;
}

.job-tab-button:hover {
  background-color: #8910a1;
  color: white;
}

.job-tab-content {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  text-align: left;
  color: #e9e3e3;
}

.job-tab-content thead {
  background-color: #58056d;
  color: white;
}

.job-tab-content th {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

.job-tab-content td {
  border: 1px solid #ddd;
  padding: 15px;
  vertical-align: top;
  font-size: 14px; /* Adjust font size for smaller screens */
}

.job-table-header {
  font-weight: bold;
}

.job-table-row-header {
  font-weight: bold;
}

.job-icon {
  color: #ac1dd8;
  font-size: 20px;
  margin-right: 10px;
  display: inline-block;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .job-main-heading {
    font-size: 24px; /* Reduce font size for smaller screens */
  }

  .job-tab-button {
    font-size: 14px; /* Smaller font size for buttons */
    padding: 8px 10px; /* Adjust padding for smaller buttons */
  }

  .job-tab-content td {
    font-size: 12px; /* Reduce table content font size */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .job-tab-content th {
    font-size: 16px; /* Adjust table header font size */
  }

  .job-icon {
    font-size: 16px; /* Smaller icons for compact layout */
  }
}
