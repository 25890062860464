.reach-us-container {
    display: flex;
    padding: 50px;
  }
  
  .left-section {
    width: 50%;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .left-section h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
  }
  
  .form-group input, .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
  }
  
  .submit-btn {
    background-color: #6f42c1;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .right-section {
    width: 50%;
    padding: 30px;
    background-color: #e9ecef;
  }
  
  .right-section h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .right-section p {
    color: #555;
    margin-bottom: 30px;
  }
  
  .points-list {
    list-style-type: none;
    padding: 0;
    
  }
  
  .right-section .points-list li {
    margin-bottom: 15px;
    font-size: 1.1rem;
    color: black;
    display: flex;
    align-items: center;
  }
  
  .points-list li .icon {
    margin-right: 10px;
    background-color: #6f42c1;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
  }
  
  @media (max-width: 1024px) {
    .reach-us-container {
      flex-direction: column;
    }
  
    .left-section, .right-section {
      width: 100%;
    }
  }
  