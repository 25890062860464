/* Contact Form Floating Style */
.contact-form {
  position: fixed;
  top: 3%;
  right: 20px;
  width: 300px;
  height: auto;
  background: #ffffff; /* Purple shade */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  padding:15px;
  border-radius: 12px;
  z-index: 3000;
  color: rgb(43, 0, 56);
}

/* Contact Icon Floating Style */
.contact-icon {
  position: fixed;
  top: 17%;
  right: 5%;
  z-index: 3000;
  cursor: pointer;
}

.contact-icon img {
  width: 50px;
  height: 50px;
}

/* Form Header and Cancel Button */
.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.contact-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.cancel-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #ff1100; /* Red color for cancel button */
  cursor: pointer;
}

/* Form Elements */
.contact-form form div {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #e1bee7; 
  color: #000000; 
}

.contact-form textarea {
  resize: vertical;
  height: 100px;
}

.contact-form button.submit {
  width: 100%;
  padding: 12px;
  background: #7b1fa2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.contact-form button.submit:hover {
  background: #4a148c; /* Even darker purple */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .contact-form {
    width: 80%;
    right: 10px;
    top: 10%;
  }

  .contact-icon {
    right: 10px;
    top: 20%;
  }

  .contact-icon img {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
    width: 90%;
    right: 25px;
    top: 2%;
  }

  .contact-icon {
    right: 80px;
    top: 7%;
  }

  .contact-icon img {
    width: 50px;
    height: 50px;
  }
}
