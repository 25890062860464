.call-icon {
  position: fixed;
  right: 25px;
  top: 75vh;
  z-index: 998;

  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
}

.call-icon img {
  width: 50px;
  height: 50px;
}

@media (max-width: 768px) {
  .call-icon {
    top: 72vh;
    right: 8px;
    padding: 8px;
    z-index: 10;
  }

  .call-icon img {
    width: 45px;
    height: 45px;
  }
}