/* General Styles */
.slider-container h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #fffefe;
}

.slider {
    position: relative;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 2rem;
}

.slider-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
}

.slide {
    min-width: 100%;

    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0.5;
 
    transform: scale(0.8);

}

.slide img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.center-slide {
    opacity: 1;
    /* Highlight the active slide */
    transform: scale(1);
   
}

/* Navigation Buttons */
.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .slider-container {
        width: 95%;
    }

    .slide img {
        height: 300px;
    }

    .prev,
    .next {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .slider-container {
        width: 100%;
    }

    .slide img {
        height: 350px;
    }

    .prev,
    .next {
        width: 30px;
        height: 30px;
        font-size: 14px;
    }

    slider {
        padding: .5rem;
        position: relative;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        margin-bottom: 2rem;
    }

    .slider-inner {
        display: flex;
        transition: transform 0.5s ease-in-out;
        will-change: transform;
    }
}