.footer {
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid rgba(128, 128, 128, 0.514);
}
.footer::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgba(117, 0, 117, 0.297);
  filter: blur(40px);
  z-index: -1;
  animation: waveUpDown 6s ease-in-out infinite;
}

@keyframes waveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.footer-logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.footer-logo h3 {
  font-size: 1.5rem;
  margin: 0;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;
  text-align: start;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #f1c40f;
}
.social-icons {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-icons a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #f1c40f; /* Hover color */
}

/* Accordion Styles */
.accordion-item {
  margin-bottom: 10px;
}

.accordion-toggle {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  font-size: 2rem;
  padding: 10px 0;
  width: 100%;
  text-decoration: none;
  transition: color 0.3s ease;
}

.accordion-toggle:hover {
  color: #f1c40f;
}

.accordion-item .accordion-toggle {
  /* border: 1px solid green; */
  font-weight: 500;
  width: 60%;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: -10px;
}

/* Accordion Content Styles */
.accordion-content {
  display: none;
  padding-left: 20px;
}

.accordion-content.active {
  display: block;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    text-align: center;
    gap: 0;
  }

  .footer-section {
    margin: 20px 0;
  }

  .accordion-toggle {
    border: 1px solid gray;
    text-align: center;
  }

  .accordion-content{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .accordion-content button{
    text-align: center;
    border: 1px solid gray;
  }
}