/* Skeleton Loader Styles */
.skeleton-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  height: 400px;
  overflow: hidden;

}

.skeleton-loader {
  background: linear-gradient(90deg, #333 25%, #444 50%, #333 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: shimmer 1.2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeleton-title {
  height: 20px;
  width: 80%;
  margin-bottom: 15px;
}

.skeleton-paragraph,
.skeleton-subheading {
  height: 15px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-image {
  height: 200px;
  width: 100%;
  margin-bottom: 15px;
}

/* Blog List Container */
.blog-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  margin-top: 4rem
}

/* Blog List Card */
.blog-card {
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #555;
  width: 250px;
  height: auto;
  padding: 15px;
  transition: transform 0.3s ease, border 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.05);
  border: 1px solid rgb(144, 0, 255);
}


.blog-card {
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #555;
  width: 250px;
  height: 450px;
  /* Set a fixed height to maintain uniformity */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, border 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.05);
  border: 1px solid rgb(144, 0, 255);
}

/* Blog list Card Elements */
.blog-card-title {
  font-size: 1rem;
  font-weight: 700;
  color: #ffffffce;
  text-align: left;
  margin-bottom: 10px;
  flex-grow: 0;
  /* Keeps the heading from stretching */
}

.blog-card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 5px;
  flex-grow: 0;
  /* Keeps the image size fixed */
}

.blog-card-paragraph {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #ccc;
  font-family: 'Times New Roman', Times, serif;
}

.read-more-btn {
  display: block;
  text-align: center;
  padding: 5px;
  margin-top: auto;
  /* Ensures the button is at the bottom */
  font-size: 15px;
  background-color: purple;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
}

/* Blog Detail Page */
.blog-detail-container {
  width: 96%;
  margin: 5rem auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-size: 2.5rem;
  text-align: start;
  margin-bottom: 20px;
  font-weight: bold;
}

.blog-subheading {
  font-size: 1.5rem;
  font-family: Poppins;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.736);
}

.blog-image {
  width: 100%;
  max-width: 450px;
  display: block;
  max-height: 450px;
}

.blog-paragraph {
  font-size: 1.1rem;
  color: #ddd;
}

.contact-sapalogy {
  padding: 20px 0;
  max-width: 600px;
  border-radius: 10px;
  color: #ddd;
}

.contact-sapalogy h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  text-align: center;
  /* Center align the heading for smaller screens */
}

.contact-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  /* Stack items on smaller screens */
}

.contact-item p {
  margin: 0;
  font-weight: bold;
}

.contact-item a {
  color: #007bff;
  text-decoration: none;
  word-break: break-word;
  /* Handle long URLs */
}

.contact-item a:hover {
  text-decoration: underline;
}

.blog-proposter {
  margin: 0 auto;
  max-width: 600px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .md\\:flex {
    display: block;
    /* Stack elements instead of flex layout */
  }

  .md\\:justify-around {
    justify-content: flex-start;
    /* No justification needed when stacked */
  }

  .contact-sapalogy {
    padding: 15px;
    /* Reduce padding for smaller screens */
    max-width: 100%;
    /* Allow full width on smaller devices */
  }

  .contact-item {
    margin-bottom: 10px;
    /* Reduce margin for tighter spacing */
  }

  .contact-sapalogy h2 {
    font-size: 1.5rem;
    /* Slightly smaller heading for mobile */
  }
}

@media (max-width: 480px) {
  .contact-sapalogy h2 {
    font-size: 1.2rem;
    /* Further reduce heading size for very small screens */
  }

  .contact-item a {
    font-size: 0.9rem;
  }
}


/* Responsive Design */
@media screen and (max-width: 1024px) {
  .blog-card {
    width: 45%;
  }

  .blog-detail-container {
    width: 95%;
  }

  .blog-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .blog-card {
    width: 100%;
    height: auto;
  }

  .blog-card-image {
    max-height: 300px;
  }

  .blog-title {
    font-size: 1.5rem;
  }

  .blog-paragraph {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .blog-title {
    font-size: 1.2rem;
  }

  .blog-lastpara {
    font-family: 'Times New Roman', Times, serif;
  }

  .blog-proposter {
    margin: 0 auto;
    max-width: 300px;
  }

}