.subscribe-demo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.subscribe-card-content {
  width: 100%;
  text-align: center;
}

.subscribe-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #fff;
}

.subscribe-text p {
  font-size: 1rem;
  color: #fff;
  line-height: 1.6;
  margin-bottom: 10px;
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.benefit-card i {
  font-size: 2rem;
  color: #fbff00;
  margin-bottom: 10px;
}

.benefit-card p {
  font-size: 1rem;
  color: #fff;
  text-align: center;
}

.subscribe-img-section {
  margin-top: 20px;
}

.subscribe-button {
  font-size: 1rem;
  color: #fff;
  background-color: #4c92be;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #35789f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .benefits-grid {
    flex-direction: column;
    align-items: center;
  }

  .benefit-card {
    width: 90%;
  }
}