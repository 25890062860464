.devops-wrapper {
    position: relative;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .devops-heading {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .devops-points {
    list-style: none;
    padding-left: 0;
  }
  
  .devops-points li {
    font-size: 18px;
    margin: 10px 0;
    position: relative;
    color: #ffffff;
  }
  
  .devops-points li::before {
    content: "●";
    color: #b300ff;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .devops-circle {
    position: absolute;
    border-radius: 50%;
    filter: blur(15px);
    z-index: -1;
  }
  
  .devops-circle-top-right {
    top: 0;
    right: 0;
    width: 190px;
    height: 190px;
    opacity: 60%;
    background: rgba(117, 15, 161, 0.6); /* Purple color with transparency */
    animation: moveCircleTopRight 6s infinite alternate;
  }
  
  .devops-circle-bottom-left {
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
    opacity: 60%;
    background: rgba(110, 8, 158, 0.615); /* Slightly different purple */
    animation: moveCircleBottomLeft 8s infinite alternate;
  }
  
  @keyframes moveCircleTopRight {
    0% {
      transform: translate(20px, 20px);
    }
    100% {
      transform: translate(-20px, -20px);
    }
  }
  
  @keyframes moveCircleBottomLeft {
    0% {
      transform: translate(-20px, -20px);
    }
    100% {
      transform: translate(20px, 20px);
    }
  }
  
  @media (max-width: 768px) {
    .devops-wrapper {
      padding: 20px;
    }
    
    .devops-heading {
      font-size: 28px;
    }
  
    .devops-points li {
      font-size: 16px;
    }
  
    .devops-circle-top-right, .devops-circle-bottom-left {
      width: 80px;
      height: 80px;
    }
  }
  