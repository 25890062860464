.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 10px 20px; /* Padding on both sides */
  position: relative;
  border-bottom: 1px solid rgb(64, 64, 64);
}

.marquee-text {
  margin: 10px 0;
  display: inline-block;
  position: relative;
  font-size: 17px;
  padding: 0 20px; /* Padding within the text block */
  transform: translateX(100%);
  animation: marquee 15s linear forwards; /* Stops at the center in normal mode */
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  50% { transform: translateX(calc(50vw - 50%)); }
  100% { transform: translateX(calc(50vw - 50%)); }
}

/* Media query for small screens */
@media (max-width: 600px) {
  .marquee-text {
    animation: marquee-responsive 20s linear infinite; 
  }
}


@keyframes marquee-responsive {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); } 
}

@media (max-width: 400px) {
  .marquee {
    padding: 5px 5px; /* Further reduce padding */
  }
  .marquee-text {
    font-size: 17px; 
    padding: 0 10px;  
  }
}
