/* src/components/Hero.css */
.hero-section {
    position: relative;
    background-image: url('../assets/Bg1.webp');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    color: #fff;
}

.hero-content {
    max-width: 700px;
}

.hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero-content ul li {
    font-size: 24px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.hero-content ul li svg {
    margin-right: 10px;
    color: #6F42C1;

}

.enquiry-form {
    background-color: rgba(255, 255, 255, 0.2);
    /* Transparent background */
    padding: 20px;
    border-radius: 8px;
    max-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10rem;
}

.enquiry-form h2 {
    margin-bottom: 15px;
    font-size: 24px;
}
.enquiry-form input{
color: black;
}
.enquiry-form input,
.enquiry-form textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.enquiry-form button {
    width: 100%;
    padding: 10px;
    background-color: #6c089a;
    /* Button color */
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.enquiry-form button:hover {
    background-color: #5a34a4;
    /* Darker shade on hover */
}

@media (max-width: 768px) {

    .hero-content {
        margin-top: 2rem;
    }

    .hero-content h1 {
        /* margin-top: -10rem; */
        font-size: 28px;
        margin-left: -6px;
        position: relative;
        bottom: 20px;
    }

    .hero-content ul li {
        font-size: 17px;
        position: relative;
        bottom: 25px;
    }

    .hero-section {
        flex-direction: column;
        /* Stack elements on smaller screens */
        align-items: center;
        /* Center elements */
        padding: 20px;
        margin-top: 4rem;

    }

    .enquiry-form {
        max-width: 70%;
        max-height: 60%;
        position: relative;
        bottom: 30px;
        text-align: center;

        /* Add some space above */
    }

    .enquiry-form h2 {
        font-size: 15px;
    }
    .enquiry-form select{
        font-size: 12px;
    }

    .enquiry-form input,
    .enquiry-form textarea {
        width: 100%;
        font-size: 12px;
    }

    .enquiry-form button {
        width: 90%;
        font-size: 12px;
    }
}