.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.left-side {
  flex: 1;
  padding: 20px;
  max-width: 50%;
}

.left-side h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.left-side h2 {
  font-size: 1.2rem;
  line-height: 1.5;
}

.right-side {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  padding: 20px;
}

.box {
  position: relative;
  width: calc(50% - 10px);
  height: 150px;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box:hover {
  transform: scale(1.05);
}

.box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  opacity: 1;
  transition: background-color 0.3s ease;
}

.box:hover .overlay {
  background-color: rgba(0, 0, 0, 0); /* Hide overlay background on hover */
  color: #000; /* Change text color on hover */
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1; /* Behind the overlay */
  transition: background-color 0.3s ease;
}

.box:hover::before {
  background-color: currentColor; /* Use the color defined in hover */
}

.top-left {
  background-image: url('../assets/image.webp'); 
  color: #c047df; 
}

.top-right {
  background-image: url('../assets/y.jpg');
  color: #c047df; 
}

.bottom-left {
  background-image: url('../assets/d.jfif'); 
  color: #c047df; 
}

.bottom-right {
  background-image: url('../assets/N.jpg'); 
  color: #c047df; 
}
.left-side ul {
  list-style-type: disc; 
  padding-left: 20px; 
}

.left-side li {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-side,
  .right-side {
    max-width: 100%;
    padding: 10px;
  }

  .box {
    width: 100%;
    height: 120px; /* Adjust as needed */
  }
}
