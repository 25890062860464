.cart-section {
    text-align: center;
    padding: 40px 20px;
  }
  
  .cart-section-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #ffffff;
  }
  
  .cart-section-header p {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 50px;
    padding: 30px;
  }
  
  .cart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px; /* Add gap between carts */
 
  }
  
  .cart {
    position: relative;
    width: 24%;
    height: 400px;
    background: transparent;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    border:1px solid #5900ff;
  }
  
  .cart-content {
    position: relative;
    z-index: 2;
    padding: 20px;
    text-align: left;
  }
  
  .cart-content h3 {
    margin-top: 0;
  }
  
  .enquire-btn {
    position: absolute;
    top: 350px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #6f42c1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cart-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .cart:hover .cart-image {
    transform: scale(1.2);
  }
  
  .hover-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cart:hover .hover-info {
    opacity: 1;
  }
  
  .know-more-underline {
    padding-top: 10px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    .cart {
      width: 48%;
    }
  }
  
  @media (max-width: 768px) {
    .cart {
      width: 100%;
    }
  }
  