/* Outer container for width control */
.marquee-container {
    overflow: hidden;
    position: relative;
    width: 100%; /* Adjust to desired width (e.g., 100%, 80vw, etc.) */
    padding: 10px 0;
  }
  
  /* Marquee strip for scrolling */
  .marquee-strip {
    display: inline-flex;
    animation: marquee-scroll 8s linear infinite;
    width: calc(200%); /* Adjusted for continuous scrolling */
  }
  
  /* Each platform item */
  /* .marquee-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 5px 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  } */
  
  /* Platform logo styling */
  .marquee-logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  /* Details container for name and rating */
  /* .marquee-details {
    display: flex;
    flex-direction: column;
  } */
  /* Adjust marquee item to allow for wider name containers */
.marquee-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 5px 10px;
    /* border: 1px solid #fff; */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 230px; /* Set a minimum width to ensure enough space */
  }
  
  /* Adjust details container to take available width */
  .marquee-details {
    display: flex;
    flex-direction: column;
    flex: 1; /* Allow this container to take the remaining space */
    overflow: hidden; /* Hide overflow if necessary */
  }
  
  /* Platform name styling */
  .marquee-name {
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    overflow: hidden;
  }
  
  /* Platform rating styling */
  .marquee-rating {
    font-size: 13px;
    color: rgb(255, 255, 255);
  }
  
 

  @keyframes marquee-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  @media (max-width: 768px) {
    .marquee-strip {
      animation: marquee-scroll 10s linear infinite; /* Faster animation for mobile */
    }
  
    @keyframes marquee-scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-300%); /* Ensure it scrolls completely to the end */
      }
    }
  }
  