.alumni-marquee-section {
  width: 100%;
  padding: 20px 0;
  background-color: transparent;
  /* Light background for contrast */
}

.alumni-marquee-section h1 {
  text-align: center;
  font-weight: bold;
  color: #ffffff8c;
}

/* Flexbox for the company cards */
.company-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  /* Adjusts spacing to be more flexible */
}

/* Responsive company logo */
.company-icon {
  width: 130px;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .alumni-marquee-section {
    padding: 15px 0;
  }

  .company-item {
    margin: 0 10px;
  }

  .company-icon {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .alumni-marquee-section h1 {
    font-size: 1.5rem;
  }

  .company-icon {
    width: 50px;
  }
}
