.learning-outcome-section {
    text-align: center;
    padding: 50px;
  }
  
  .section-heading {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #ffffff;
  }
  .sub-heading{
    margin:auto;
  }
  
  .card-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    height: 500px;
  }
  
  .outcome-card {
    position: relative;
    width: 300px;
    transition: transform 0.3s ease, border-color 0.3s ease;
    border: 3px solid transparent;
    overflow: hidden;
  }
  
  .outcome-card.hovered {
    transform: scale(1.05);
    border-color: #800080; /* Purple */
  }
  
  .card-img-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }
  
  .card-img.color {
    filter: grayscale(0%);
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .card-description {
    margin-top: 15px;
    font-size: 1rem;
    color: #ffffff;
  }
  

@media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      gap: 30px;
    }
  
    .outcome-card {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
    }
  
    .card-img-wrapper {
      height: 180px;
    }
  
    .card-title {
      font-size: 1.2rem;
    }
  
    .card-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .section-heading {
      font-size: 1.8rem;
    }
  
    .outcome-card {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
    }
  
    .card-img-wrapper {
      height: 150px;
    }
  
    .card-title {
      font-size: 1rem;
    }
  
    .card-description {
      font-size: 0.85rem;
    }
  }