.batches-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
  justify-content: space-between; /* Space between sections for better alignment */
  
}

.left-cont {
  flex: 1;
  padding: 20px;
  min-width: 320px; /* Adjusted for smaller devices */
  max-width: 690px; /* Restrict the max width */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: rgba(128, 0, 128, 0.4);



}

.left-cont h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 36px; /* Slightly smaller for better scaling */
  color: white; /* Consistent branding color */
}

.right-content {
  flex: 1;
  max-width: 700px; /* Restrict max width for better scaling */
  text-align: center;
  padding: 20px;
}

.right-content img {
  width: 100%;
  max-width: 1200px; /* Slightly smaller max width */
  object-fit: contain; /* Ensure full image visibility */
}

.tabs button {
  margin-right: 10px;
  padding: 8px 16px; /* Increased padding for better usability */
  background-color: grey; /* Neutral default color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem; /* Improved text readability */
}

.tabs .active {
  background: #5A34A4;
  color: #ffffff;
  font-weight: bold; /* Highlight active tab */
}

.batch {
  background-color: #ffffff;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap; /* Stack content on smaller screens */
  gap: 10px; /* Space between items */
}

.batch span {
  flex: 1;
  text-align: left; /* Align content for better readability */
  font-size: 0.9rem;
  color: #333333;
}

.batch button {
  padding: 8px 16px;
  color: #ffffff;
  background-color: #6C089A;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.batch button:hover {
  background-color: #442154;
}

/* Corporate Form - Improved Layout */
.corporate-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto; /* Center form on the page */
  border: 1px solid grey;
}

.corporate-form h3 {
  color: black;
  margin-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
}

.corporate-form label {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333333;
}

.corporate-form input,
.corporate-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 0.9rem;
}

.corporate-form button {
  background-color: #7B1FA2;
  color: #ffffff;
  padding: 10px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}


.form-status {
  margin-top: 1rem;
  font-size: 1rem;
  color: green;
}
.error {
  color: red;
  font-size: 0.9rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .batches-container {
    flex-direction: column;
    position: relative;
  
    padding: 18px;
  }

  .left-content,
  .right-content {
    width: 100%;
    max-width: 100%; /* Ensure full width on smaller devices */
  }
  .right-content img {
    width: 120%;
    max-width: 120%;
    height: auto; /* Ensure the image scales proportionally */
    position: relative;
    right: 25px;
  }
  
  .schedule .batch {
    flex-direction: column;
    align-items: flex-start;
  }

  .batch span {
    text-align: left;
    font-size: 0.85rem;
  }

  .batch button {
    width: 100%;
    margin-top: 10px;
  }
}
