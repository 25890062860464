.career-marquee-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between the marquee rows */
  background-color: white;/* Space between the marquee rows */
}

.career-marquee-heading {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: white; /* Adjusted for better contrast */
  font-weight: bold;
}


.career-logo {
  width: 130px; /* Set a fixed width for all logos */
  height: 130px; /* Set a fixed height for all logos */
  margin-right: 50px; /* Space between logos in the same row */
  object-fit: contain; /* Ensure logos maintain their aspect ratio */
}

@media (max-width: 768px) {
  .career-logo {
    width: 80px; /* Adjust size for smaller screens */
    height: 80px;
    margin-right: 15px; /* Adjust space between logos */
  }
}
