@media (max-width: 600px) {
  
    .MuiTypography-h5 {
        font-size: 1rem;
    }
    .MuiTypography-h6 {
        font-size: .8rem; 
    }
    
}

@media (max-width: 600px) {
    .marquee img {
        width: 10%; 
    }
}
