.whatsapp-icon {
  position: absolute;
  top: 85vh;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
}

.whatsapp-icon img {
  width: 60px;
  height: 60px;
}

@media (max-width: 768px) {
  .whatsapp-icon {
    top: 79vh;
    right: 1px;
    z-index: 10;

  }

  .whatsapp-icon img {
    width: 55px;
    height: 55px;
  }
}